import { Link } from "gatsby"
import * as React from "react"

// Startseite, Ueber mich, Angebote, Kontakt

export function Nav() {
  return (
    <ul className="nav text-uppercase justify-content-center bg-secondary">
      <li className="nav-item">
        <Link to="/" activeClassName="active" className="nav-link text-light" aria-current="page">
          Start
        </Link>
      </li>
      <li className="nav-item">
        <Link to="/ueber-mich" activeClassName="active" className="nav-link text-light">
          Über Mich
        </Link>
      </li>
      <li className="nav-item">
        <Link to="/angebote" activeClassName="active" className="nav-link text-light">
          Angebote
        </Link>
      </li>
      <li className="nav-item">
        <Link to="/kontakt" activeClassName="active" className="nav-link text-light">
          Kontakt
        </Link>
      </li>
      <li className="nav-item">
        <Link to="/impressum" activeClassName="active" className="nav-link text-light">
          Impressum/AGB/Disclaimer
        </Link>
      </li>
    </ul>
  )
}
