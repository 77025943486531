import * as React from "react"

export interface ISubtitleProps {
  subtitle: string
}

export function Subtitle(props: ISubtitleProps) {
  const { subtitle } = props
  return (
    <h2 className="fs-4 my-2">
      <b>{subtitle}</b>
    </h2>
  )
}
