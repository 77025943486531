import * as React from "react"

export interface ITitleProps {
  title: string
}

export function Title(props: ITitleProps) {
  const { title } = props
  return (
    <h1 className="my-5">
      <b>{title}</b>
    </h1>
  )
}
