import * as React from "react"
import { Subsubtitle } from "../../layout/Subsubtitle"
import { Subtitle } from "../../layout/Subtitle"
import { Title } from "../../layout/Title"

export function Impressum() {
  return (
    <>
      <Title title="Allgemeine Geschäftsbedingungen / Impressum / Disclaimer" />
      <Subtitle subtitle="1. Disclaimer für all meine Angebote" />
<p>
    Meine Beratungen sowie Angebote ersetzen keine/n Arzt/in, Heilpraktiker/in,
    Psychiater/in, Psychotherapeuten oder sonstige/n Experten/in auf
    seinem/ihrem Fachgebiet. Die von mir gegebenen Hilfestellungen bzw.
    Angebote stellen keinen Ersatz für schulmedizinische Maßnahmen,
    Behandlungen oder Therapien. Jegliche Aussagen, Antworten, Empfehlungen,
    Angebote sind ohne Gewähr. Sie können begleitend und unterstützend sein, um
    gemeinsam mit der Schulmedizin oder Therapiemaßnahmen Hand in Hand zu
    gehen. Jeder Klient/in ist selbst verantwortlich für das Hinzuziehen und in
    Anspruch nehmen eines/m Experten/in. Ich gebe weder Heilversprechen,
    Zukunftsprognosen ab, noch stelle ich Diagnosen oder gebe welche im
    schulmedizinischen Sinne ab. Bitte beachten Sie bei Buchung meiner
    Angebote, zusätzlich den Vermerk bei Angebote Punkt 3. Healing –
    Energieübertragungstool. Jegliche Haftung mir gegenüber ist hiermit
    ausgeschlossen. Jeder Termin beruht auf freiwilliger Basis und setzt eine
    psychische Grundstabilität und Selbstverantwortung voraus.
</p>
<h2>
    2. Kosten
</h2>
<h2>
    2.1 Höhe der Kosten
</h2>
<p>
    Die Kosten entnehmen Sie bitte unter dem jeweiligen Angebot bei Beratung,
    diese entsprechen der aktuellen Preisliste. Sie setzen sich aus der
    einzelnen Anwendung und der dafür benötigen Zeit zusammen.
</p>
<p>
    Info für dich: Private Zusatzversicherungen können je nach Polizze einen
    Behandlungszuschuss für Beratungen oder Präventivmaßnahmen gewähren. Bitte
    frage bei deiner Versicherung nach und kläre dies individuell ab!
</p>
<h2>
    2.2 Zahlungsmethoden
</h2>
<p>
    Folgenden Zahlungsmethoden können Sie vereinbaren:
</p>
<ul>
    <li>
        Barzahlung vor Ort
    </li>
    <li>
        Zahlung mittels Banküberweisung:
    </li>
    <li>
        IBAN: AT39 2051 0000 0170 6704
        <br/>
        BIC: SPSCAT22XXX
    </li>
</ul>
<p>
    Sollte keine Zahlungsmethode vorab vereinbart worden sein, gilt
    grundsätzlich Barzahlung.
</p>
<p>
    Das Zahlungsziel beträgt 10 Tage netto ohne Abzug nach Erhalt der Rechnung.
    Bei Verzug der Bezahlung der Rechnung, können zusätzlich Verzugszinsen in
    der gesetzlichen Höhe von 4% in Rechnung gestellt werden.
</p>
<h2>
    3. Terminabsage
</h2>
<p>
    Falls du einen vereinbarten Termin nicht wahrnehmen kannst, bitte ich dich
    dies spätestens 24 Stunden vor dem vereinbarten Termin – telefonisch
    mitzuteilen. Andernfalls behalte ich mir das Recht vor, den nicht
    wahrgenommenen Termin, in voller Höhe der gebuchten Anwendung, in Rechnung
    zu stellen.
</p>
<h2>
    4. Persönliche Termine
</h2>
<p>
    Können immer nur unter den aktuell geltenden Sicherheits- und
    Hygienemaßnahmen stattfinden.
</p>
<h2>
    Impressum
</h2>
<p>
    Sabrina Lackner
</p>
<p>
    Lebens- und Sozialberaterin
</p>
<p>
    (gem.§94 Ziffer 46 Gewerbeordnung 1994, BGBl. I 140/2003 und BGBl. II Nr.
    112/2006)
</p>
<p>
    Steuernummer: 83037/2801
</p>
<p>
    Anschrift:
</p>
<p>
    Tratzbergstrasse
</p>
<p>
    6200 Jenbach
</p>
<p>
    Telefon:
</p>
<p>
    +43 664 350 5131
</p>
<p>
    E-Mail:
</p>
<p>
    sabrina.lackner1@gmail.com
</p>
<p>
    Verantwortlich für die Inhalte:
</p>
<p>
    Sabrina Lackner
</p>
<p>
    Christopher Frewin (Logo &amp; Websitegestaltung)
</p>
<p>
    Ich bemühe mich, auf dieser Webseite richtige und vollständige
    Informationen zur Verfügung zu stellen. Dennoch übernehme ich keine Haftung
    oder Garantie für Aktualität, Richtigkeit und Vollständigkeit der
    bereitgestellten Informationen auf dieser Webseite. Dies gilt auch für alle
    weiterführenden Links und Verbindungen auf die, diese Webseite direkt oder
    indirekt verweist. Ich bin also für jegliche Inhalte von Webseiten, die
    mittels einer solchen Verbindung erreicht werden, nicht verantwortlich!
</p>
<p>
    Der Inhalt dieser Webseite ist urheberrechtlich geschützt und somit aus
    Gründen des Urheberrechts ist die unerlaubte Speicherung und
    Vervielfältigung von Daten- sowie Bildmaterial aus dieser Webseite nicht
    gestattet.
</p>
    </>
  )
}
