import * as React from "react"
import { StaticImage } from "gatsby-plugin-image"
import * as bannerStyles from "./banner-image.module.scss"

export function BannerImage() {
  return (
    <div className="position-relative">
      <StaticImage
        className={bannerStyles.bannerImage}
        src="../../../images/background.jpg"
        alt="Verbindungsraum"
        placeholder="blurred"
      />
      <div
        className={`${bannerStyles.titleContainer} position-absolute d-flex flex-row justify-content-center align-items-center`}
      >
        <div className="me-auto">
          <StaticImage
            width={400}
            src="../../../images/logo.png"
            alt="Logo"
            placeholder="blurred"
          />
        </div>
        <div className="m-1 m-md-5 d-flex flex-wrap flex-column justify-content-center align-items-center">
          <h1 className="title text-center fw-bold">Sabrina Lackner</h1>
          <h2 className="title text-center fw-bold">
            Life Coach & Energiearbeit
          </h2>
        </div>
      </div>
    </div>
  )
}
