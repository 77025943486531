import React from "react"
import { Layout } from "../components/layout/Layout"
import { Impressum } from "../components/pages/impressum/Impressum"

export default function ImpressumPage() {
  return (
    <Layout>
      <Impressum />
    </Layout>
  )
}
